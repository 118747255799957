import {
  Avatar,
  Box,
  Chip,
  Grid2,
  Theme,
} from "@mui/material";
import { Atm, AttachMoney, LocalTaxi, Notes, Payment, PersonOutline, Phone, WorkOutline } from "@mui/icons-material";
import React from "react";
import JobTimeline from "./JobTimeline";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexTop: {
      display: "flex",
      alignItems: "top",
    },
    evenRow: {
      m: 0.5,
      cursor: "pointer"
    },
    oddRow: {
      backgroundColor: "lightgray",
      m: 0.5,
      cursor: "pointer"
    },
    notes: {
      fontStyle: "italic"
    }
  })
);

const JobRow = (props: any) => {
  const { onClick } = props
  const classes = useStyles();
  const { row, i } = props;

  return (
    <Box className={(i % 2 === 1 ? classes.evenRow : classes.oddRow)} onClick={() => onClick(row)}  >
      <Grid2 container spacing={1} justifyContent="center" alignItems="center"
      >
        <Grid2 size={1}>
          <Avatar>{i + 1}</Avatar>
          <div>{row.jobID}</div>
        </Grid2>
        <Grid2 size={2}>
          <div className={classes.flexTop}>
            <PersonOutline />
            {row.passengerName}
          </div>
          {row.passengerPhone && <div className={classes.flexTop}>
            <Phone />
            {row.passengerPhone}
          </div>}
          <div className={classes.flexTop}>
            <WorkOutline />
            {row.customer?.name}
          </div>
        </Grid2>
        <Grid2 size={4}>
          <JobTimeline row={row} />
        </Grid2>
        <Grid2 size={2}>
          <div className={classes.flexTop}>
            <LocalTaxi /> {row.activity?.name}
          </div>
          <div className={classes.flexTop}>
            <PersonOutline /> {row.driver?.name}
          </div>
        </Grid2>
        <Grid2 size={1}>
          <div className={classes.flexTop}>
            <AttachMoney /> {row.totalAmount}
          </div>
          {row.paymentMethod && <div className={classes.flexTop}>
            <Payment /> {row.paymentMethod.name}
          </div>}
          {row.isPaid && <div className={classes.flexTop}>
            Paid
          </div>}
        </Grid2>
        <Grid2 size={1}>
          <div className={classes.notes}>{row.notes}</div>
          {row.isReversePickup && <Chip variant="outlined" label="Reverse Pickup" />}
          {row.isPaid && <Chip variant="outlined" label="Paid" />}
          {row.isSentToDriver && <Chip variant="outlined" label="Sent to Driver" />}
          {row.isInvoiced && <Chip variant="outlined" label="Invoiced" />}
          {row.isFinished && <Chip variant="outlined" label="Finished" />}
          {row.isCancelled && <Chip variant="outlined" label="Cancelled" />}

        </Grid2>
      </Grid2>
    </Box >
  );
};

export default JobRow;
