import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import format from "date-fns/format";
import React from "react";
import LocationOn from "@mui/icons-material/LocationOn";
import dayjs from "dayjs";
import { FlightLand, FlightTakeoff } from "@mui/icons-material";

const JobTimeline = (props: any) => {
  const { row } = props;

  const clipboard = (loc: string) => {
    navigator.clipboard.writeText(loc);
  };

  return (
    <Timeline>
      {row.arrivalFlightNo &&
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="info">
              <FlightLand />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            {row.arrivalFlightNo}
          </TimelineContent>
        </TimelineItem>

      }

      <TimelineItem>
        <TimelineOppositeContent>
          {dayjs(row.pickupTime).format("DD-MMM HH:mm")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="primary">
            <LocationOn />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div onClick={() => clipboard(row.pickupFrom)}>{row.pickupFrom}</div>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent>
          {dayjs(row.dropTime).format("DD-MMM HH:mm")}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot color="secondary">
            <LocationOn />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent>
          <div onClick={() => clipboard(row.pickupFrom)}>{row.dropTo}</div>
        </TimelineContent>
      </TimelineItem>

      {row.departureFlightNo &&
        <TimelineItem>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot color="info">
              <FlightTakeoff />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            {row.departureFlightNo}
          </TimelineContent>
        </TimelineItem>
      }

    </Timeline>
  );
};

export default JobTimeline;
