import {
    Button, Dialog, DialogActions, DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    Theme
} from "@mui/material"
import useGetApi from "../../hooks/useGetApi";
import { Form, Formik, FormikProvider, useFormik } from "formik";
import * as yup from 'yup';
import { Close, Delete, Save } from "@mui/icons-material";
import usePostApi from "../../hooks/usePostApi";
import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import ConfirmationDialog from "../../shared/ConfirmationDialog";
import useDeleteApi from "../../hooks/useDeleteApi";
import { useSnackbar } from "notistack";
import JobPayment from "./JobPayment";
import JobCustomer from "./JobCustomer";
import JobDriver from "./JobDriver";
import Job from "../../models/Job";

const validationSchema = yup.object({
    customer: yup
        .object()
        .required('Customer is required'),
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            yOverflow: "auto",
            height: 'calc(100vh - 250px)'
        },
    })
);


const JobDetailDialog = (props: any) => {
    const { open, handleClose, id } = props
    const classes = useStyles()
    const { enqueueSnackbar } = useSnackbar();

    const [deleteDialog, setDeleteDialog] = useState(false)

    const [item, setItem] = useState<Job>({
        jobID: id,
        isTour: false,
        isReversePickup: false,
        isWedding: false,
        isInterstate: false,
        isCountryside: false,
        isTaxiReformLevyExempted: false,
        pickupTime: new Date(),
        dropTime: new Date(),
        isSentToDriver: false,
        isFinished: false,
        isInvoiced: false,
        isMYOBUpdated: false,
        isCancelled: false,
        isPaid: false,
        viaLocations: []
    } as Job)
    const { data: getData, isLoading: getIsLoading, onRefresh } = useGetApi({ url: `/jobs/get/${id}`, isDefered: id === 0 });
    const { data: postData, isLoading: postIsLoading, onUpdate } = usePostApi({ url: `/jobs/updateAndGet` });
    const { isLoading: deleteIsLoading, onDelete } = useDeleteApi({ url: `/jobs/delete` });

    useEffect(() => {
        if (!getData) return;
        setItem(getData)
    }, [getData])

    useEffect(() => {
        if (!postData) return;
        setItem(postData)
        enqueueSnackbar(`Job ${postData.jobID} saved`, { variant: 'success' })
    }, [postData])

    const onClosing = (event: any, reason: any) => {
        if (reason && reason === "backdropClick")
            return;
        handleClose();
    }

    const onDeleting = async () => {
        setDeleteDialog(false)

        try {
            await onDelete(item.jobID)
            enqueueSnackbar(`Job ${item.jobID} deleted`, { variant: 'success' })
            handleClose()
        }
        catch (ex) {
            enqueueSnackbar(JSON.stringify(ex), { variant: "error" })
        }
    }

    return <>
        <Dialog
            fullWidth
            maxWidth='xl'
            open={open}
            onClose={onClosing}
        >
            <Formik initialValues={item}
                enableReinitialize
                onSubmit={(values) => {
                    onUpdate(values)
                }}>
                {formik =>
                    <Form>
                        <DialogTitle sx={{ display: "flex" }}>
                            Job #{item.jobID}
                            <IconButton sx={{ marginLeft: "auto" }} onClick={handleClose}>
                                <Close />
                            </IconButton>

                        </DialogTitle>
                        <DialogContent className={classes.content} dividers>
                            {(getIsLoading || postIsLoading) && <LinearProgress />}
                            {formik.values && <>
                                <JobCustomer formik={formik} />
                                <JobDriver formik={formik} />
                                <JobPayment formik={formik} />
                            </>}
                        </DialogContent>
                        <DialogActions>
                            <Button startIcon={<Delete />} variant="outlined"
                                onClick={() => setDeleteDialog(true)}>Delete</Button>
                            <div style={{ flex: '1 0 0' }} />
                            <Button type="submit" startIcon={<Save />} variant="outlined">Save</Button>

                        </DialogActions>
                    </Form>
                }
            </Formik>
        </Dialog >
        {deleteDialog && <ConfirmationDialog
            open={deleteDialog}
            onYes={onDeleting}
            onNo={() => setDeleteDialog(false)} />
        }
    </>
}

export default JobDetailDialog