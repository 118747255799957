import { Autocomplete, Box, Breadcrumbs, Button, Checkbox, Divider, FormControlLabel, Grid2, Paper, Stack, TextField, Theme, Typography } from "@mui/material"
import useGetApi from "../../hooks/useGetApi";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import usePostApi from "../../hooks/usePostApi";
import * as yup from 'yup';
import { format } from "date-fns";
import { Email } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { createStyles, makeStyles } from "@mui/styles";
import JobView from "./JobView";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollBox: {
            height: `calc(100vh - 320px)`,
            overflow: "auto",
        },
    })
);

const validationSchema = yup.object({
    driver: yup.object().required('Driver is required'),
    fromDate: yup.date().required('FromDate is required'),
    toDate: yup.date().required('ToDate is required'),
});

const BookingAdvise = () => {
    const classes = useStyles();

    const { data: drivers } = useGetApi({ url: `/drivers/GetRefList` });
    const { data, onUpdate } = usePostApi({ url: `/jobs/GetBookingAdviseReport` });
    const { onUpdateNoResponse: sendEmails } = usePostApi({ url: `/jobs/EmailBookingAdviseReport` });
    const [emails, setEmails] = useState<string>()
    const { enqueueSnackbar } = useSnackbar();

    const [item, setItem] = useState<any>({
        driver: null,
        fromDate: dayjs().format('YYYY-MM-DD'),
        toDate: dayjs().format('YYYY-MM-DD'),
    })

    useEffect(() => {
        if (!data) return

        setEmails(data.emails)
    }, [data])

    const onSubmit = (values: any) => {
        setItem(values)
        onUpdate(
            {
                driverId: values.driver.driverID,
                fromDate: values.fromDate,
                toDate: values.toDate,
            })
    }

    const getWages = (lines: any) => {
        let total = 0
        lines.forEach((line: any) => {
            total += line.driverWages
        })

        return total
    }

    const onEmail = async () => {

        try {
            const data = {
                driverId: item.driver.driverID,
                fromDate: item.fromDate,
                toDate: item.toDate,
                emails: emails
            }
            console.log(data)
            await sendEmails(data)

            enqueueSnackbar(`Sent to ${emails}`, { variant: 'success' })
        }
        catch (e) {
            debugger
            enqueueSnackbar(JSON.stringify(e), { variant: 'error' })
        }
    }

    return <>
        <Box sx={{ m: 2 }}>
            <Formik initialValues={item}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {formik =>
                    <Form>
                        <Grid2 container spacing={2}>
                            <Grid2 size={2}>
                                <DatePicker
                                    name="fromDate"
                                    label="From"
                                    format="DD/MM/YYYY"
                                    value={dayjs(formik.values.fromDate)}
                                    onChange={(newValue: any) => { formik.setFieldValue('fromDate', newValue.format('YYYY-MM-DD')); }}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </Grid2>
                            <Grid2 size={2}>
                                <DatePicker
                                    name="toDate"
                                    label="To"
                                    format="DD/MM/YYYY"
                                    value={dayjs(formik.values.toDate)}
                                    onChange={(newValue: any) => { formik.setFieldValue('toDate', newValue.format('YYYY-MM-DD')); }}
                                    slotProps={{ textField: { fullWidth: true } }}
                                />
                            </Grid2>
                            <Grid2 size={4}>
                                <Autocomplete
                                    fullWidth
                                    id="driver"
                                    options={drivers ?? []}
                                    getOptionLabel={(option: any) => option?.name ?? ''}
                                    isOptionEqualToValue={(option, value) => option?.driverID === value?.driverID}
                                    value={formik.values.driver ?? ''}
                                    onChange={(_, newValue) => {
                                        formik.setFieldValue('driver', newValue)
                                    }}
                                    onBlur={formik.handleBlur}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                            name="driver"
                                            label="Driver"
                                            error={formik.touched.driver && Boolean(formik.errors.driver)}
                                        />}
                                />
                            </Grid2>
                            <Grid2>
                                <Button type="submit" variant="outlined" >Query</Button>
                            </Grid2>
                        </Grid2>
                    </Form>
                }
            </Formik>
        </Box>

        <Divider orientation="horizontal" />

        {data && <>
            <Box sx={{ m: 2 }}>
                <Grid2 container spacing={1}>
                    <Grid2 size={6}>
                        <Grid2 container spacing={1}>
                            <Grid2 size={12}>
                                <TextField label="Jobs" value={data.lines.length} aria-readonly />
                            </Grid2>
                            <Grid2 size={12}>
                                <TextField label="Wages" value={getWages(data.lines)} aria-readonly />
                            </Grid2>
                        </Grid2>
                    </Grid2>
                    <Grid2 size={6}>
                        <Grid2 container spacing={1}>
                            <Grid2 size={12}>
                                <TextField multiline name="emails" fullWidth label="Emails (Each email per line)" value={emails ?? ''} onChange={(v) => setEmails(v.target.value)} />
                            </Grid2>
                            <Button disabled={!emails} startIcon={<Email />} variant="outlined" onClick={() => onEmail()}>Email</Button>
                        </Grid2>
                    </Grid2>

                </Grid2>
            </Box>

            <Divider orientation="horizontal" />

            <Box className={classes.scrollBox}>

                {data.lines.map(
                    (row: any, index: number) =>
                        <>
                            {index > 0 && <Divider orientation="horizontal" />}
                            <JobView row={row} />
                        </>
                )}
            </Box>
        </>}
    </>
}

export default BookingAdvise