import { Box, Checkbox, FormControlLabel, Grid2 } from "@mui/material"
import { format } from "date-fns"

const JobView = (props: any) => {
    const { row } = props

    return <Box sx={{ m: 2 }} >
        <Grid2 container spacing={1}>
            <Grid2 size={1}><b>Customer:</b></Grid2>
            <Grid2 size={2}>{row.customer}</Grid2>
            <Grid2 size={3}>{row.activity}</Grid2>
            <Grid2 size={3}> {row.jobId}</Grid2>
            <Grid2 size={3}> {row.paymentMethod}</Grid2>

            <Grid2 size={1}><b>Passenger:</b></Grid2>
            <Grid2 size={5}> {row.passengerName}</Grid2>
            <Grid2 size={6}>
                <FormControlLabel control={<Checkbox checked={row.hasTrailer} />} label="Trailer" />
                <FormControlLabel control={<Checkbox checked={row.isPaid} />} label="Paid" />
                <FormControlLabel control={<Checkbox checked={row.isReversePickup} />} label="Reverse Pickup" />
                <FormControlLabel control={<Checkbox checked={row.isTour} />} label="Tour" />
            </Grid2>

            <Grid2 size={6}>
                <Grid2 container>
                    {row.arrivalFlightNo &&
                        <>
                            <Grid2 size={2}><b>Arrival Flight:</b></Grid2>
                            <Grid2 size={2}></Grid2>
                            <Grid2 size={8}>{row.arrivalFlightNo}</Grid2>
                        </>
                    }

                    <Grid2 size={2}><b>Pickup:</b></Grid2>
                    <Grid2 size={2}>{format(new Date(row.pickupTime), 'dd/MM HH:mm')}</Grid2>
                    <Grid2 size={8}>{row.pickupFrom}</Grid2>

                    {row.viaLocations.map((location: any, index: number) =>
                        <>
                            <Grid2 size={2}><b>Via #{index + 1}:</b></Grid2>
                            <Grid2 size={2}></Grid2>
                            <Grid2 size={8}>{location.address}</Grid2>
                        </>
                    )}

                    <Grid2 size={2}><b>Dropoff:</b></Grid2>
                    <Grid2 size={2}>{format(new Date(row.dropTime), 'dd/MM HH:mm')}</Grid2>
                    <Grid2 size={8}>{row.dropTo}</Grid2>

                    {row.departureFlightNo &&
                        <>
                            <Grid2 size={2}><b>Departure Flight:</b></Grid2>
                            <Grid2 size={2}></Grid2>
                            <Grid2 size={8}>{row.departureFlightNo}</Grid2>
                        </>}
                    <Grid2 size={2}><b>Wages</b></Grid2>
                    <Grid2 size={2}>{row.driverWages}</Grid2>
                </Grid2>
            </Grid2>

            <Grid2 sx={{ border: 1, borderColor: 'lightgray' }} size={6}>{row.notes}</Grid2>
        </Grid2>
    </Box>
}


export default JobView