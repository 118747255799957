import {
  Box,
  LinearProgress,
  Theme,
} from "@mui/material";
import { format } from "date-fns";
import React, { useRef, useState } from "react";
import useGetApi from "../../hooks/useGetApi";
import JobRow from "./JobRow";
import JobsHeader from "./JobsHeader";
import JobDetailDialog from "../job/JobDetailDialog";
import { createStyles, makeStyles } from "@mui/styles";
import dayjs from "dayjs";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    id: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    flexTop: {
      display: "flex",
      alignItems: "top",
    },
    jobList: {
      height: `calc(100vh - 150px)`,
      overflow: "auto",
    },
  })
);

const Jobs = () => {
  const classes = useStyles();

  const [filter, setFilter] = useState({
    fromDate: dayjs().format('YYYY-MM-DD'),
    toDate: dayjs().format('YYYY-MM-DD')
  });

  const url = `/jobs/getList2?fromDate=${filter.fromDate}&toDate=${filter.toDate}`;
  const { data, isLoading, onRefresh } = useGetApi({ url });
  const [open, setOpen] = useState(false)
  const [jobId, setJobId] = useState<any>(undefined)

  const onClick = (row: any) => {
    setOpen(true)
    setJobId(row.jobID)
  }

  const onNew = () => {
    setOpen(true)
    setJobId(0)
  }

  const handleClose = () => {
    onRefresh()
    setOpen(false)
    setJobId(undefined)
  }

  return (
    <>


      {data &&
        <>
          {/* header */}
          <JobsHeader onNew={onNew} data={data} onRefresh={onRefresh} filter={filter} setFilter={setFilter} />
          {/* {isLoading && <LinearProgress />} */}

          {/* list */}
          <Box className={classes.jobList}>
            {data.map((row: any, i: number) => (
              <JobRow key={row.jobID} row={row} i={i} onClick={onClick} />
            ))}
          </Box>
        </>
      }

      {open && <JobDetailDialog open={open} id={jobId} handleClose={handleClose} ></JobDetailDialog>}
    </>
  );
};

export default Jobs;
